.input-group {
  @apply my-2 flex flex-col first:mt-0 last:mb-0 only:my-0;
}

.input-group .input {
  @apply block w-full border border-off-white bg-off-white px-xs py-[7px];
}

.input-group.dark .input {
  @apply block w-full border border-black bg-black p-3;
}

.input-group .checkbox {
  @apply flex;
}
.input-group .radio {
  @apply flex;
}

.input-group .checkbox .label {
  @apply mb-0 font-normal normal-case;
}

.input-group .radio .label {
  @apply mb-0 font-normal normal-case;
}

.input-group .input[type='checkbox'],
.input-group .input[type='radio'] {
  @apply mr-2 w-auto accent-black;
}

.input-group .input[type='text'],
.input-group textarea {
  @apply outline-none focus:border-off-white;
}

.input-group.dark .input[type='text'],
.input-group.dark textarea {
  @apply focus:border-black;
}

.input-group .multi-checkbox {
  @apply flex items-center;
}

.input-group .label {
  @apply mb-2 block font-gotham text-paragraph font-n-light not-italic leading-desktop-small;
}

.input-group.dark .label {
  @apply text-white/75;
}

.input-group .data-consent .label {
  @apply text-center text-btn font-s-light not-italic leading-tiny-paragraph text-white/90;
}

.input-group .input[type='text'],
.input-group textarea,
.input-group .datepicker .popover-title,
.input-group .dropdown .popover-title {
  @apply font-gotham text-paragraph font-s-light not-italic leading-desktop-small;
}

.input-group.dark .input[type='text'],
.input-group.dark textarea,
.input-group.dark .datepicker .popover-title,
.input-group.dark .dropdown .popover-title {
  @apply text-white;
}

/* Placeholder */
.input-group .input {
  @apply placeholder:font-gotham placeholder:text-paragraph placeholder:font-s-light placeholder:not-italic placeholder:leading-desktop-small placeholder:text-black/50;
}

.input-group.dark .input {
  @apply placeholder:text-white/50;
}

.input-group .datepicker .popover-title.placeholder,
.input-group .dropdown .popover-title.placeholder {
  @apply font-gotham text-paragraph font-s-light not-italic leading-desktop-small text-black/50;
}

/* Dropdown */
.input-group .datepicker,
.input-group .dropdown {
  @apply border-0 p-0;
}

.input-group .datepicker .main-button,
.input-group .dropdown .main-button {
  @apply h-[40px] border border-off-white px-xs py-[7px] transition-colors duration-300;
}

.input-group .datepicker.open.bottom .main-button,
.input-group .dropdown.open.bottom .main-button {
  @apply border-black/25 border-b-transparent;
}

.input-group .datepicker.open.top .main-button,
.input-group .dropdown.open.top .main-button {
  @apply border-black/25 border-t-transparent;
}

.input-group .datepicker .content,
.input-group .dropdown .content {
  @apply border-black/25 bg-white;
}

.input-group .datepicker.open.bottom .content,
.input-group .dropdown.open.bottom .content {
  @apply border-t-0;
}

.input-group .datepicker.open.top .content,
.input-group .dropdown.open.top .content {
  @apply border-b-0;
}

.input-group .datepicker.open,
.input-group .dropdown.open {
  @apply border-black/25 bg-white;
}

.input-group .dropdown.open .option {
  @apply text-paragraph font-s-light not-italic leading-desktop-small tracking-paragraph hover:bg-grey6 focus:bg-grey6;
}

.datepicker .arrow svg,
.dropdown .arrow svg,
.dropdown.dark.open .arrow svg {
  @apply stroke-black;
}

.datepicker.dark .arrow svg,
.dropdown.dark .arrow svg {
  @apply stroke-white;
}

/* End Dropdown */

.input-group.error .input[type='text'],
.input-group.error textarea {
  @apply text-error placeholder:text-error;
}

.input-group.error.dark .input[type='text'],
.input-group.error.dark textarea {
  @apply text-error-dark;
}

.input-group.error .input,
.input-group.error .input:focus {
  @apply border-error;
}

.input-group.dark.error .input {
  @apply border-error-dark;
}

.input-group.error .datepicker .arrow svg,
.input-group.error .dropdown .arrow svg {
  @apply stroke-error;
}

.input-group.error .datepicker .main-button,
.input-group.error .dropdown .main-button {
  @apply border-error;
}

.input-group.error .datepicker .placeholder,
.input-group.error .dropdown .placeholder {
  @apply text-error;
}

.input-group.error .error-message {
  @apply font-gotham font-n-light text-error;
}

.input-group .input.number-input {
  @apply flex;
}

.input-group .input.number-input input[type='number']::-webkit-outer-spin-button,
.input-group .input.number-input input[type='number']::-webkit-inner-spin-button {
  @apply m-0;
  -webkit-appearance: none;
}

.input-group .input.number-input input[type='number'] {
  @apply w-4/5 border-0 bg-transparent text-center;
  -moz-appearance: textfield;
  outline: none;
}

.input-group .input.number-input input[type='number']:focus-visible {
  @apply border-0;
  outline: none;
}

.input-group .input.datepicker {
  @apply p-0;
}

.radio-group {
  @apply flex text-btn font-normal uppercase not-italic leading-tiny-paragraph;
}
.input-group .radio-group,
.input-group .multi-checkbox {
  @apply cursor-pointer;
}

.input-group:has(input:disabled),
.input-group:has(input:disabled) .number-input > *,
.input-group:has(input:disabled) label:has(input:disabled),
button:disabled {
  @apply cursor-not-allowed;
}

.input-group .input.number-input input[type='number']:disabled {
  @apply bg-transparent;
}

.select {
  @apply grow bg-transparent px-4 py-2.5 font-gotham text-paragraph font-medium uppercase tracking-[.96px] text-white outline-none placeholder:text-white;
}

.input-common {
  @apply rounded-full border border-white/25 p-2 font-larken text-paragraph uppercase tracking-[.96px] text-white outline-none backdrop-blur-[13px];
  background: rgba(255, 255, 255, 0.02);
}

.input-common option {
  @apply rounded-full border text-paragraph uppercase tracking-[.96px] text-black outline-none;
}

input[type='color'] {
  @apply rounded-full border border-white/25 p-0 text-paragraph uppercase tracking-[.96px] text-white outline-none backdrop-blur-[13px];
  background: rgba(255, 255, 255, 0.02);
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  @apply rounded-full border border-white/25 text-paragraph uppercase tracking-[.96px] text-white outline-none backdrop-blur-[13px];
  background: rgba(255, 255, 255, 0.02);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
}

.NewsletterModule input:-webkit-autofill,
.NewsletterModule input:-webkit-autofill:hover,
.NewsletterModule input:-webkit-autofill:focus,
.NewsletterModule input:-webkit-autofill:active {
  -webkit-text-fill-color: #ffffff;
}

.field-iWouldLikeToReceiveTheElevenNewsletter .label {
  @apply mb-0 capitalize;
}

.field-iWouldLikeToReceiveTheElevenNewsletter {
  @apply gap-s;
}
